.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
v .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");

* {
  font-family: "sen" !important;
}

[class*="ant-dropdown"] {
  z-index: 1300 !important;
}

[class*="ant-collapse"] {
  border: none !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  margin: 2% auto;
  text-align: center;
  font-size: 14px;
  font-family: Verdana;
}

.select-css {
  display: block;
  font-size: 16px;
  font-family: "Verdana", sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.4em 1.4em 0.3em 0.8em;
  width: 160px;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
  border-radius: 0.3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;

  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

.classOfElementToColor:hover {
  background-color: red;
  color: black;
}

.select-css option[selected] {
  background-color: orange;
}

/* OTROS ESTILOS*/
.styled-select {
  width: 240px;
  height: 34px;
  overflow: hidden;
  border: 1px solid #ccc;
}

.sidebar-box select {
  display: block;
  padding: 5px 10px;
  height: 42px;
  margin: 10px auto;
  min-width: 225px;
  -webkit-appearance: none;
  height: 34px;
  /* background-color: #ffffff; */

  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.pswp {
  z-index: 1567890;
  background-color: red;
}
